import { Col, Radio, Row } from "antd";
import { UsersApi } from "api/api";
import { UserFilterType } from "api/models";
import { ExportButtonPageHeader } from "components/buttons/export-button/export-button";
import { Cards } from "components/cards/frame/cards-frame";
import { useAuthorization } from "hooks/useAuthorization";
import { useTableSorting } from "hooks/useTableSorting";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AutoComplete } from "../../components/autoComplete/autoComplete";
import { Button } from "../../components/buttons/buttons";
import { PageHeader } from "../../components/page-headers/page-headers";
import { CardToolbox, Main, TopToolBox } from "../../container/styled";
import UsersTable from "./UsersTable";
import { ExportButtonPageApiHeader } from "components/buttons/export-button/export-button-api";
import openNotificationWithIcon from "utility/notification";

const usersApi = new UsersApi();

const UsersPage = () => {
  const { t } = useTranslation();
  const { hasPermission } = useAuthorization();

  const filterKeys = [
    { id: UserFilterType.ACTIVE, name: t("users:select.active", "Active") },
    {
      id: UserFilterType.PENDING,
      name: t("users:select.not-confirmed", "Not Confirmed"),
    },
    hasPermission("users:list-deactivated") && {
      id: UserFilterType.DISABLED,
      name: t("users:select.disabled", "Disabled"),
    },
    // {
    //   id: UserFilterType.DISABLED,
    //   name: t("users:select.disabled", "Disabled"),
    // },
    { id: UserFilterType.DELETED, name: t("users:select.deleted", "Deleted") },
    { id: UserFilterType.ALL, name: t("users:select.all", "All") },
  ].filter(Boolean);

  const [usersData, setUsersData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { sorting, onSorterChange } = useTableSorting();

  const [request, setRequest] = useState({
    filterType: UserFilterType.ACTIVE,
    search: "",
    pageNumber: 1,
    pageSize: 10,
  });

  useEffect(() => {
    if (request)
      getUsers();
  }, [request, sorting]);

  const getUsers = async () => {
    setIsLoading(true);
    const { data } = await usersApi.usersListGet({ ...request, ...sorting });
    setUsersData(data);
    setIsLoading(false);
  };

  const onFilterChange = (e) => {
    setRequest({
      ...request,
      filterType: e.target.value,
      pageNumber: 1,
      pageSize: 10,
    });
  };

  const onclickNotConfirmedEmailSend =  async () => {
    setIsLoading(true);
    const response = await usersApi.usersNotConfirmationPost();
    if (response.status === 200) {
      openNotificationWithIcon(
        "success",
        t("users:notification.resend-confirmation-emails", "Successfully Resend Confirmation Emails"),
      );
    }else{
      openNotificationWithIcon(
        "error",
        t("users:notification.resend-confirmation-error", "Faild to Resend Confirmation Emails"),
      );
    }
    setIsLoading(false);
  }

  const onSearchChange = (value) => {
    setRequest({ ...request, search: value });
  };

  const onPaginationChange = (pageNumber) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber }));
  };

  const onShowSizeChange = (pageNumber, pageSize) => {
    setRequest((prevQuery) => ({ ...prevQuery, pageNumber, pageSize }));
  };

  return (
    <>
      <CardToolbox>
        <PageHeader
          ghost
          title={t("users:users-title", "Users Management")}
          buttons={[

              <ExportButtonPageApiHeader key="1" callFrom={'User'} filterType={request.filterType} municipalityId={""} entityId={""} search={request.search}/>,
              hasPermission("users:add") && (
                <Button
                  className="btn-add_new"
                  size="default"
                  type="primary"
                  key="btn_2"
                >
                  <Link to="/users/create">
                    {t("users:button.add", "+ Add New User")}
                  </Link>
                </Button>
              )

          ]}
        />
      </CardToolbox>

      <Main>
        <Cards headless>
          <Row gutter={15}>
            <Col xs={24}>
              <TopToolBox>
                <Row gutter={15}>
                  <Col lg={6} md={6} xs={24}>
                    <div className="table-search-box">
                      <AutoComplete
                        onSearch={(value) => onSearchChange(value)}
                        width="100%"
                        patterns
                      />
                    </div>
                  </Col>
                  <Col lg={12} md={14} xs={24}>
                    <div
                      className="table-toolbox-menu"
                      style={{ float: "left", marginLeft: 20 }}
                    >
                      <span className="toolbox-menu-title">
                        {t("users:lable.status", "Status:")}
                      </span>
                      <Radio.Group
                        name="filterKey"
                        onChange={(e) => onFilterChange(e)}
                        value={request?.filterType}
                      >
                        {[...new Set(filterKeys)].map((item, i) => {
                          return (
                            <Radio.Button key={item.id + '_' + i} value={item.id}>
                              {item.name}
                            </Radio.Button>
                          );
                        })}
                      </Radio.Group>
                    </div>
                  </Col>
                { request.filterType === UserFilterType.PENDING && <Col lg={6} md={6} xs={24} style={{display:'flex',justifyContent:'end'}}>
                    <Button
                    className="btn-add_new"
                    size="default"
                      onClick={onclickNotConfirmedEmailSend}
                    type="primary"
                    key="btn_2"
                     >
                     Resend Confirmation Emails
                    </Button>
                  </Col>}
                </Row>
              </TopToolBox>
            </Col>
          </Row>
          <Row gutter={0}>
            <UsersTable
              data={usersData}
              isLoading={isLoading}
              onPaginationChange={(val) => onPaginationChange(val)}
              onShowSizeChange={(pageNumber, pageSize) => onShowSizeChange(pageNumber, pageSize)}
              onSorterChange={(val) => onSorterChange(val)}
              refetch={() => getUsers()}
            />
          </Row>
        </Cards>
      </Main>
    </>
  );
}

export default UsersPage;
