/* tslint:disable */
/* eslint-disable */
/**
 * KGH PROJECT API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AddUserToGroupCommand } from '../models';
// @ts-ignore
import { CreateUserCommand } from '../models';
// @ts-ignore
import { SetNewPasswordRequest } from '../models';
// @ts-ignore
import { ToggleUserActivateCommand } from '../models';
// @ts-ignore
import { UpdateLanguageRequest } from '../models';
// @ts-ignore
import { UpdateProfileCommand } from '../models';
// @ts-ignore
import { UpdateUserCommand } from '../models';
// @ts-ignore
import { UserDto } from '../models';
// @ts-ignore
import { UserDtoPaginatedList } from '../models';
// @ts-ignore
import { UserFilterType } from '../models';
import { ToggleUserDeleteCommand } from 'api/models/toggle-user-delete-command';
/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAvailableEmailGet: async (email: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('usersAvailableEmailGet', 'email', email)
            const localVarPath = `/users/available/{email}`
                .replace(`{${"email"}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdDelete: async (id: string, body?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdDelete', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdGet', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut: async (id: string, updateUserCommand?: UpdateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdPut', 'id', id)
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersNotConfirmationPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            const localVarPath = `/users/not-confirm-users`
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdResendConfirmationPost: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdResendConfirmationPost', 'id', id)
            const localVarPath = `/users/{id}/resend-confirmation`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {ToggleUserActivateCommand} [toggleUserActivateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdTooglePut: async (id: string, toggleUserActivateCommand?: ToggleUserActivateCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdTooglePut', 'id', id)
            const localVarPath = `/users/{id}/toogle`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(toggleUserActivateCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
                 * 
                 * @param {string} id 
                 * @param {ToggleUserDeleteCommand} [toggleUserDeleteCommand] 
                 * @param {*} [options] Override http request option.
                 * @throws {RequiredError}
                 */
        usersIdDeleteTooglePut: async (id: string, toggleUserDeleteCommand?: ToggleUserDeleteCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('usersIdDeleteTooglePut', 'id', id)
            const localVarPath = `/users/{id}/toogle-delete`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(toggleUserDeleteCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },

        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [roleId] 
         * @param {string} [search] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListGet: async (pageNumber?: number, pageSize?: number, roleId?: string, search?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (pageNumber !== undefined) {
                localVarQueryParameter['PageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (roleId !== undefined) {
                localVarQueryParameter['RoleId'] = roleId;
            }

            if (search !== undefined) {
                localVarQueryParameter['Search'] = search;
            }

            if (filterType !== undefined) {
                localVarQueryParameter['FilterType'] = filterType;
            }

            if (sortingPropertyName !== undefined) {
                localVarQueryParameter['Sorting.PropertyName'] = sortingPropertyName;
            }

            if (sortingIsDescending !== undefined) {
                localVarQueryParameter['Sorting.IsDescending'] = sortingIsDescending;
            }



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProfileCommand} [updateProfileCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMePut: async (updateProfileCommand?: UpdateProfileCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(updateProfileCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost: async (createUserCommand?: CreateUserCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(createUserCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLanguageRequest} [updateLanguageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetDefaultLanguagePut: async (updateLanguageRequest?: UpdateLanguageRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/set-default-language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(updateLanguageRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SetNewPasswordRequest} [setNewPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetNewPasswordPost: async (setNewPasswordRequest?: SetNewPasswordRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/set-new-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(setNewPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddUserToGroupCommand} [addUserToGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserGroupsPost: async (addUserToGroupCommand?: AddUserToGroupCommand, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/user-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)



            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(addUserToGroupCommand, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersAvailableEmailGet(email: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersAvailableEmailGet(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdDelete(id: string, body?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdDelete(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdGet(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdPut(id: string, updateUserCommand?: UpdateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdPut(id, updateUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersNotConfirmationPost( options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersNotConfirmationPost( options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
          /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
          async usersIdResendConfirmationPost(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdResendConfirmationPost(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {ToggleUserActivateCommand} [toggleUserActivateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersIdTooglePut(id: string, toggleUserActivateCommand?: ToggleUserActivateCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdTooglePut(id, toggleUserActivateCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },

        /**
   * 
   * @param {string} id 
   * @param {ToggleUserDeleteCommand} [toggleUserDeleteCommand] 
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   */
        async usersIdDeleteTooglePut(id: string, toggleUserDeleteCommand?: ToggleUserDeleteCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersIdDeleteTooglePut(id, toggleUserDeleteCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },



        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [roleId] 
         * @param {string} [search] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersListGet(pageNumber?: number, pageSize?: number, roleId?: string, search?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDtoPaginatedList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersListGet(pageNumber, pageSize, roleId, search, filterType, sortingPropertyName, sortingIsDescending, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMeGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMeGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateProfileCommand} [updateProfileCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersMePut(updateProfileCommand?: UpdateProfileCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersMePut(updateProfileCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersPost(createUserCommand?: CreateUserCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersPost(createUserCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateLanguageRequest} [updateLanguageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSetDefaultLanguagePut(updateLanguageRequest?: UpdateLanguageRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSetDefaultLanguagePut(updateLanguageRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SetNewPasswordRequest} [setNewPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersSetNewPasswordPost(setNewPasswordRequest?: SetNewPasswordRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersSetNewPasswordPost(setNewPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AddUserToGroupCommand} [addUserToGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async usersUserGroupsPost(addUserToGroupCommand?: AddUserToGroupCommand, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.usersUserGroupsPost(addUserToGroupCommand, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @param {string} email 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersAvailableEmailGet(email: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.usersAvailableEmailGet(email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {boolean} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdDelete(id: string, body?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.usersIdDelete(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdGet(id: string, options?: any): AxiosPromise<UserDto> {
            return localVarFp.usersIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {UpdateUserCommand} [updateUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdPut(id: string, updateUserCommand?: UpdateUserCommand, options?: any): AxiosPromise<void> {
            return localVarFp.usersIdPut(id, updateUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdResendConfirmationPost(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.usersIdResendConfirmationPost(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {ToggleUserActivateCommand} [toggleUserActivateCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersIdTooglePut(id: string, toggleUserActivateCommand?: ToggleUserActivateCommand, options?: any): AxiosPromise<void> {
            return localVarFp.usersIdTooglePut(id, toggleUserActivateCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {string} [roleId] 
         * @param {string} [search] 
         * @param {UserFilterType} [filterType] 
         * @param {string} [sortingPropertyName] 
         * @param {boolean} [sortingIsDescending] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersListGet(pageNumber?: number, pageSize?: number, roleId?: string, search?: string, filterType?: UserFilterType, sortingPropertyName?: string, sortingIsDescending?: boolean, options?: any): AxiosPromise<UserDtoPaginatedList> {
            return localVarFp.usersListGet(pageNumber, pageSize, roleId, search, filterType, sortingPropertyName, sortingIsDescending, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMeGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.usersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProfileCommand} [updateProfileCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersMePut(updateProfileCommand?: UpdateProfileCommand, options?: any): AxiosPromise<void> {
            return localVarFp.usersMePut(updateProfileCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateUserCommand} [createUserCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersPost(createUserCommand?: CreateUserCommand, options?: any): AxiosPromise<void> {
            return localVarFp.usersPost(createUserCommand, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLanguageRequest} [updateLanguageRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetDefaultLanguagePut(updateLanguageRequest?: UpdateLanguageRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersSetDefaultLanguagePut(updateLanguageRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SetNewPasswordRequest} [setNewPasswordRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersSetNewPasswordPost(setNewPasswordRequest?: SetNewPasswordRequest, options?: any): AxiosPromise<void> {
            return localVarFp.usersSetNewPasswordPost(setNewPasswordRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddUserToGroupCommand} [addUserToGroupCommand] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        usersUserGroupsPost(addUserToGroupCommand?: AddUserToGroupCommand, options?: any): AxiosPromise<void> {
            return localVarFp.usersUserGroupsPost(addUserToGroupCommand, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for usersAvailableEmailGet operation in UsersApi.
 * @export
 * @interface UsersApiUsersAvailableEmailGetRequest
 */
export interface UsersApiUsersAvailableEmailGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersAvailableEmailGet
     */
    readonly email: string
}

/**
 * Request parameters for usersIdDelete operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdDeleteRequest
 */
export interface UsersApiUsersIdDeleteRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersIdDelete
     */
    readonly id: string

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiUsersIdDelete
     */
    readonly body?: boolean
}

/**
 * Request parameters for usersIdGet operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdGetRequest
 */
export interface UsersApiUsersIdGetRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersIdGet
     */
    readonly id: string
}

/**
 * Request parameters for usersIdPut operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdPutRequest
 */
export interface UsersApiUsersIdPutRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersIdPut
     */
    readonly id: string

    /**
     * 
     * @type {UpdateUserCommand}
     * @memberof UsersApiUsersIdPut
     */
    readonly updateUserCommand?: UpdateUserCommand
}

/**
 * Request parameters for usersIdResendConfirmationPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdResendConfirmationPostRequest
 */
export interface UsersApiUsersIdResendConfirmationPostRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersIdResendConfirmationPost
     */
    readonly id: string
}

/**
 * Request parameters for usersIdTooglePut operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdTooglePutRequest
 */
export interface UsersApiUsersIdTooglePutRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersIdTooglePut
     */
    readonly id: string

    /**
     * 
     * @type {ToggleUserActivateCommand}
     * @memberof UsersApiUsersIdTooglePut
     */
    readonly toggleUserActivateCommand?: ToggleUserActivateCommand
}



/**
 * Request parameters for usersIdTooglePut operation in UsersApi.
 * @export
 * @interface UsersApiUsersIdTooglePutRequest
 */
export interface UsersApiUsersIdDeleteTooglePutRequest {
    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersIdDeleteTooglePut
     */
    readonly id: string

    /**
     * 
     * @type {ToggleUserDeleteCommand}
     * @memberof UsersApiUsersIdDeleteTooglePut
     */
    readonly toggleUserActivateCommand?: ToggleUserDeleteCommand
}

/**
 * Request parameters for usersListGet operation in UsersApi.
 * @export
 * @interface UsersApiUsersListGetRequest
 */
export interface UsersApiUsersListGetRequest {
    /**
     * 
     * @type {number}
     * @memberof UsersApiUsersListGet
     */
    readonly pageNumber?: number

    /**
     * 
     * @type {number}
     * @memberof UsersApiUsersListGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersListGet
     */
    readonly roleId?: string

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersListGet
     */
    readonly search?: string

    /**
     * 
     * @type {UserFilterType}
     * @memberof UsersApiUsersListGet
     */
    readonly filterType?: UserFilterType

    /**
     * 
     * @type {string}
     * @memberof UsersApiUsersListGet
     */
    readonly sortingPropertyName?: string

    /**
     * 
     * @type {boolean}
     * @memberof UsersApiUsersListGet
     */
    readonly sortingIsDescending?: boolean
}

/**
 * Request parameters for usersMePut operation in UsersApi.
 * @export
 * @interface UsersApiUsersMePutRequest
 */
export interface UsersApiUsersMePutRequest {
    /**
     * 
     * @type {UpdateProfileCommand}
     * @memberof UsersApiUsersMePut
     */
    readonly updateProfileCommand?: UpdateProfileCommand
}

/**
 * Request parameters for usersPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersPostRequest
 */
export interface UsersApiUsersPostRequest {
    /**
     * 
     * @type {CreateUserCommand}
     * @memberof UsersApiUsersPost
     */
    readonly createUserCommand?: CreateUserCommand
}

/**
 * Request parameters for usersSetDefaultLanguagePut operation in UsersApi.
 * @export
 * @interface UsersApiUsersSetDefaultLanguagePutRequest
 */
export interface UsersApiUsersSetDefaultLanguagePutRequest {
    /**
     * 
     * @type {UpdateLanguageRequest}
     * @memberof UsersApiUsersSetDefaultLanguagePut
     */
    readonly updateLanguageRequest?: UpdateLanguageRequest
}

/**
 * Request parameters for usersSetNewPasswordPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersSetNewPasswordPostRequest
 */
export interface UsersApiUsersSetNewPasswordPostRequest {
    /**
     * 
     * @type {SetNewPasswordRequest}
     * @memberof UsersApiUsersSetNewPasswordPost
     */
    readonly setNewPasswordRequest?: SetNewPasswordRequest
}

/**
 * Request parameters for usersUserGroupsPost operation in UsersApi.
 * @export
 * @interface UsersApiUsersUserGroupsPostRequest
 */
export interface UsersApiUsersUserGroupsPostRequest {
    /**
     * 
     * @type {AddUserToGroupCommand}
     * @memberof UsersApiUsersUserGroupsPost
     */
    readonly addUserToGroupCommand?: AddUserToGroupCommand
}

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {UsersApiUsersAvailableEmailGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersAvailableEmailGet(requestParameters: UsersApiUsersAvailableEmailGetRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersAvailableEmailGet(requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersIdDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdDelete(requestParameters: UsersApiUsersIdDeleteRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdDelete(requestParameters.id, requestParameters.body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdGet(requestParameters: UsersApiUsersIdGetRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdGet(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersIdPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdPut(requestParameters: UsersApiUsersIdPutRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdPut(requestParameters.id, requestParameters.updateUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersIdResendConfirmationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdResendConfirmationPost(requestParameters: UsersApiUsersIdResendConfirmationPostRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdResendConfirmationPost(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

       /**
     * 
     * @param {UsersApiUsersIdResendConfirmationPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
       public usersNotConfirmationPost( options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersNotConfirmationPost( options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersIdTooglePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdTooglePut(requestParameters: UsersApiUsersIdTooglePutRequest, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersIdTooglePut(requestParameters.id, requestParameters.toggleUserActivateCommand, options).then((request) => request(this.axios, this.basePath));
    }



    /**
     *
     * @param {UsersApiUsersIdDeleteTooglePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersIdDeleteTooglePut(
        requestParameters: UsersApiUsersIdDeleteTooglePutRequest,
        options?: AxiosRequestConfig
    ) {
        return UsersApiFp(this.configuration)
            .usersIdDeleteTooglePut(
                requestParameters.id,
                requestParameters.toggleUserActivateCommand,
                options
            )
            .then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersListGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersListGet(requestParameters: UsersApiUsersListGetRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersListGet(requestParameters.pageNumber, requestParameters.pageSize, requestParameters.roleId, requestParameters.search, requestParameters.filterType, requestParameters.sortingPropertyName, requestParameters.sortingIsDescending, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersMeGet(options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersMePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersMePut(requestParameters: UsersApiUsersMePutRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersMePut(requestParameters.updateProfileCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersPost(requestParameters: UsersApiUsersPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersPost(requestParameters.createUserCommand, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersSetDefaultLanguagePutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSetDefaultLanguagePut(requestParameters: UsersApiUsersSetDefaultLanguagePutRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersSetDefaultLanguagePut(requestParameters.updateLanguageRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersSetNewPasswordPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersSetNewPasswordPost(requestParameters: UsersApiUsersSetNewPasswordPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersSetNewPasswordPost(requestParameters.setNewPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UsersApiUsersUserGroupsPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public usersUserGroupsPost(requestParameters: UsersApiUsersUserGroupsPostRequest = {}, options?: AxiosRequestConfig) {
        return UsersApiFp(this.configuration).usersUserGroupsPost(requestParameters.addUserToGroupCommand, options).then((request) => request(this.axios, this.basePath));
    }
}
